
import React, { useEffect, useState } from "react"
import { Script } from "gatsby"
import Cookies from 'js-cookie'
import { graphql } from "gatsby";


const isBrowser = typeof window !== "undefined"

export const DefaultGtag = () => <Script id="default-gtag-values">{`
  if (!window.tasSet) {
    window.tasSet = true
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { window.dataLayer.push(arguments); }
    window.gtag('set', 'did', true);
    window.gtag('consent', 'default', { 'analytics_storage': 'denied' });
    window.gtag('consent', 'default', { 'ad_storage': 'denied' });
  }
`}</Script>

export const CookieBanner = ({ t }) => {
    const [showCookieBanner, setShowCookieBanner] = useState(false)

    useEffect(() => {
        if (isBrowser) {
            if (Cookies.get('cookie-feedback-given-tas')) {
                if (Cookies.get('cookie-feedback-tas')) {
                    try {
                    window.gtag('consent', 'update', { 'analytics_storage': 'granted' });
                    window.gtag('consent', 'update', { 'ad_storage': 'granted' });
                    } catch (e) {
                        setTimeout(() => {
                            window.gtag('consent', 'update', { 'analytics_storage': 'granted' });
                            window.gtag('consent', 'update', { 'ad_storage': 'granted' });
                        }, 500)
                    }
                }
            }
            else {
                setShowCookieBanner(true);
            }
        }
    }, [])

    return <>
        <div className={showCookieBanner ? "show-cookie-banner" : "hide-cookie-banner"} style={{
            background: "#2F4858",
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: "9999999999"
        }} onClick={() => {
            window.gtag('consent', 'update', { 'ad_storage': 'granted' });
            window.gtag('consent', 'update', { 'analytics_storage': 'granted' });
        }}>
            <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ color: "white", fontWeight: "bold" }}>{t(`site-cookie`)}</div>
                <div style={{ display: "flex", gap: "1rem", textAlign: "center" }}>
                    <div className="clickable" style={{ padding: "1rem", color: "white", fontWeight: "bold", cursor: "pointer", borderRadius: "6px" }}
                        onClick={(e) => {
                            e.preventDefault()
                            setShowCookieBanner(false)
                            Cookies.set('cookie-feedback-given-tas', true)
                            Cookies.set('cookie-feedback-tas', false)
                        }}>{t(`reject-cookie`)}</div>
                    <div className="clickable" style={{ padding: "1rem", background: "#00747F", color: "white", fontWeight: "bold", borderRadius: "6px", cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault()
                            window.gtag('consent', 'update', { 'analytics_storage': 'granted' });
                            window.gtag('consent', 'update', { 'ad_storage': 'granted' });
                            Cookies.set('cookie-feedback-given-tas', true)
                            Cookies.set('cookie-feedback-tas', true)
                            setShowCookieBanner(false)
                        }}>{t(`accept-cookie`)}</div>
                </div>
            </div>
        </div>
    </>
}

export function registerUrl(tier, plan) {
    var registerUrl = "https://admin.takeaseat.io/register"

    if (Cookies.get("solution")) {
        registerUrl += `?solution=${Cookies.get("solution")}`
    }
    else {
        if (isBrowser) {
            if (window.location.pathname.endsWith("/solutions/digital-menu/") || window.location.pathname.endsWith("/live-demo/digital-menu/")) {
                registerUrl += "?solution=digital-menu"
            } else if (window.location.pathname.endsWith("/solutions/website/") || window.location.pathname.endsWith("/live-demo/website/")) {
                registerUrl += "?solution=website"
            } else if (window.location.pathname.endsWith("/solutions/reservations/") || window.location.pathname.endsWith("/live-demo/reservations/")) {
                registerUrl += "?solution=reservations"
            } else if (window.location.pathname.endsWith("/solutions/surveys/") || window.location.pathname.endsWith("/live-demo/surveys/")) {
                registerUrl += "?solution=surveys"
            } else {
                registerUrl += "?solution=all"
            }
        } else {
            registerUrl += "?solution=all"
        }
    }

    if (tier) {
        registerUrl += `&tier=${tier}`
    }

    if (plan) {
        registerUrl += `&plan=${plan}`
    }

    return registerUrl
}

export function faqUrl() {
    var faqUrl = "/faq/"

    if (isBrowser) {
        if (window.location.pathname.endsWith("/solutions/digital-menu/") || window.location.pathname.endsWith("/live-demo/digital-menu/")) {
            faqUrl += "#digital-menu"
        } else if (window.location.pathname.endsWith("/solutions/website/") || window.location.pathname.endsWith("/live-demo/website/")) {
            faqUrl += "#website"
        }
    } else {
        faqUrl += "#digital-menu"
    }

    return faqUrl
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "joinus", "green"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;